const agencyFilterBlock = document.querySelector('.agency-filter-block')
export const BOOKINGS_DEFAULT_ORDER = 'near_to_checkin_date'

const DATE_TYPES = {
  arrival: 'arrival',
  booking: 'booking',
}

export const getFilterValues = (page, perPage) => {
  if (!agencyFilterBlock) return {}

  const filterDate = {
    start: agencyFilterBlock.querySelector('.js-filter-start-date')?.value || '',
    end: agencyFilterBlock.querySelector('.js-filter-end-date')?.value || '',
  }

  const selectedDateType = agencyFilterBlock.querySelector('.js-filter-date-type')?.value || ''
  const clientName = agencyFilterBlock.querySelector('#client_name').value
  const hotelId = agencyFilterBlock.querySelector('#hotel_id').value
  const bookingCode = agencyFilterBlock.querySelector('#booking_code').value

  const filterValues = {
    ...(hotelId ? { hotel_id: hotelId } : {}),
    ...(bookingCode ? { booking_code: bookingCode } : {}),
    ...(selectedDateType === DATE_TYPES.arrival && filterDate.start ? { with_arrival_date_start: filterDate.start } : {}),
    ...(selectedDateType === DATE_TYPES.arrival && filterDate.end ? { with_arrival_date_end: filterDate.end } : {}),
    ...(selectedDateType === DATE_TYPES.booking && filterDate.start ? { with_booking_date_start: filterDate.start } : {}),
    ...(selectedDateType === DATE_TYPES.booking && filterDate.end ? { with_booking_date_end: filterDate.end } : {}),
    with_book_status: Boolean(agencyFilterBlock.querySelector('#with_book_status:checked')),
    with_cancel_status: Boolean(agencyFilterBlock.querySelector('#with_cancel_status:checked')),
    with_pending_status: Boolean(agencyFilterBlock.querySelector('#with_pending_status:checked')),
    with_payment_paid: Boolean(agencyFilterBlock.querySelector('#with_payment_paid:checked')),
    with_payment_unpaid: Boolean(agencyFilterBlock.querySelector('#with_payment_unpaid:checked')),
    ...(clientName ? { client_name: clientName } : {}),
    order_by: BOOKINGS_DEFAULT_ORDER,
    ...(page ? { page } : {}),
    ...(perPage ? { per_page: perPage } : {}),
  }

  return filterValues
}

export const getFilterValuesSerialized = () => {
  const filterValues = getFilterValues()
  return new URLSearchParams(filterValues).toString()
}
