import { currentDevice } from '../../../core/utils/currentDevice'

// Select tag with multiple selection

// Example of html
/*
<div class="js-multi-select" data-multiselect-content-selector="#multiselect-content">
  <label for="" class="t-fb-label">Selecciona opción/es</label>
  <p class="js-multi-select-label" data-text-selected="Seleccionados"></p>
  <ul id="multiselect-content">
    <li class="item">
      <input type="checkbox" name="option1" id="option1" class="js-multi-select-option">
      <label for="option1">Opción 1</label>
    </li>
    <li class="item">
      <input type="checkbox" name="option2" id="option2" class="js-multi-select-option">
      <label for="option2">Opción 2</label>
    </li>
    <li class="item">
      <button class="js-multi-select-apply">Aplicar</button>
    </li>
  </ul>
</div>
*/

// Updates multiselect label Selected items number
export const refreshMultiSelectLabel = function (multiSelectContainer) {
  const totalOptionsSelected = multiSelectContainer.querySelectorAll('.js-multi-select-option:checked').length
  const label = multiSelectContainer.querySelector('.js-multi-select-label')
  label.textContent = `${label.dataset.textSelected} (${totalOptionsSelected})`
}

export const init = function () {
  document.querySelectorAll('.js-multi-select').forEach(multiSelect => {
    refreshMultiSelectLabel(multiSelect)

    const dropdownContainerSelector = multiSelect.dataset.multiselectContentSelector

    if (!dropdownContainerSelector) return

    const dropdownContainer = document.querySelector(dropdownContainerSelector)

    if (!dropdownContainer) return

    const multiSelectLabel = multiSelect.querySelector('.js-multi-select-label')

    function getSelectedOptions() {
      const currentOptions = multiSelect.querySelectorAll('.js-multi-select-option')
      return Array.from(currentOptions).reduce(function (filtered, option) {
        if (option.checked) {
          filtered.push(option.id)
        }
        return filtered
      }, [])
    }

    let optionsSelected = getSelectedOptions()

    // Resets options to last applied value
    function resetOptions() {
      dropdownContainer.querySelectorAll('.js-multi-select-option').forEach(option => {
        option.checked = optionsSelected.includes(option.id)
      })
    }

    multiSelectLabel.addEventListener('click', () => {
      dropdownContainer.classList.toggle('active')
    })

    dropdownContainer.querySelector('.js-multi-select-apply').addEventListener('click', () => {
      refreshMultiSelectLabel(multiSelect)

      optionsSelected = getSelectedOptions()

      dropdownContainer.classList.remove('active')
    })

    if (!currentDevice.isMobile) {
      // close on click outside element and input
      window.addEventListener('mousedown', function (event) {
        if (!multiSelect.contains(event.target)) {
          dropdownContainer.classList.remove('active')
          resetOptions()
        }
      })
    }
  })
}

init()
