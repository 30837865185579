import { getLazyData } from '../../utils/get_lazy_data';
import { getParameterByName } from '../../utils/get_parameter_by_name';
import { setCookie, readCookie } from '../../utils/cookie_manager';

const LIGHTBOX_VISIBILITY_FLAG = 'no_lb'; // must be equal to param specified in app/views/lightbox_messages/_promocode.html.erb

if (shouldShowLightbox()) {
  console.log('should show lb', shouldShowLightbox());
  const GET_LIGHTBOX_ENDPOINT = `/lightbox_messages?target=${getTarget()}`;

  getLazyData(GET_LIGHTBOX_ENDPOINT, (response) => {
    if (response.content) {
      $.fancybox.open(response.content, {
        afterShow: () => response.type == 'wizard' && attachEvents(),
        beforeClose: function() {
          const checkbox = document.getElementById('lightbox-messages-checkbox');
          checkbox && checkbox.checked && response.id && notifyCheckSeenLightbox(response.id);
        }
      });
    } else {
      setCookie(LIGHTBOX_VISIBILITY_FLAG, true, 1); // do not check if there is any lightbox to show in the next 24h
    }
  }, 'json');
}

function shouldShowLightbox() {
  return readCookie(LIGHTBOX_VISIBILITY_FLAG) === undefined &&
    getParameterByName(LIGHTBOX_VISIBILITY_FLAG) === null &&
    (IB.currentPageType.isHome() || document.querySelector('[data-agency-bookings-index]'));
}

function getTarget() {
  if (IB.currentPageType.isHome()) return IB.currentPageType.get();
  if (document.querySelector('[data-agency-bookings-index]') !== null) return 'my_bookings';
}

function notifyCheckSeenLightbox(lightbox_id) {
  const CHECK_SEEN_LIGHTBOX_ENDPOINT = `lightbox_messages/${lightbox_id}`;
  getLazyData(CHECK_SEEN_LIGHTBOX_ENDPOINT, () => {}, 'json', { method: 'PUT' });
}

function attachEvents() {
  const nextBtns = document.getElementsByClassName('js-ligthbox-messages-next');
  let count = 1;

  if (nextBtns.length) {
    for (let nextBtn of nextBtns) {
      nextBtn.addEventListener('click', () => {
        document.querySelector('.lightbox-messages .active') &&
          document.querySelector('.lightbox-messages .active').classList.remove('active');
        document.querySelector(`.lightbox-messages .step-${count}`).classList.add('active');
        count++;
      })
    }
  }
}
