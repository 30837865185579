import '../elements/multi_select'
import '../../../elements/spinner/spinner.scss'
import '../../../shared/price_guaranteed_modal/price_guaranteed_modal'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { currentDevice } from '../../../core/utils/currentDevice'
import inViewport from '../../../utils/inViewport'
import isInViewport from '../../../utils/isInViewport.js'
import watchJob from '../../../utils/watch_job.js'
import { getFilterValues, getFilterValuesSerialized } from '../shared/filter_search'

dayjs.extend(customParseFormat)
;(function ($, undefined) {
  if (typeof IB === 'undefined') {
    window.IB = {}
  } else if (IB.iberostar_pro !== undefined) {
    return
  }

  function init() {
    const $agents_form = $('.agents-form')
    const $formz = $agents_form.find('.formz')
    const language = document.documentElement.getAttribute('data-js-lang')
    const date_format = $('html').attr('data-date-format')
    const start_of_week = $('html').attr('data-start-of-week')
    const start_date = new dayjs().subtract(5, 'year').format(date_format)
    const end_date = new dayjs().add(2, 'year').format(date_format)
    const $el = $('.agency-filter-block')
    let $check_in = $el.find('.check-in')
    let $check_out = $el.find('.check-out')
    const is_inline = typeof $el.data('inline') !== 'undefined'
    const agency_user_name = $.cookie('agency_user_web_name')
    const $form = $('.form-wrapper')
    const $form_new_agency = $('.agents-form.register').find('form')
    const $form_users = $('.form-users')
    const $form_change_password_user = $('.change-password')
    const filterForm = document.querySelector('.js-agency-filter-form')

    FORMZ.required_fields.init($formz.find('[data-parsley-required]:not([data-parsley-errors-container])'))

    $('[data-wrapper="management_type"]').hide()
    $('[data-wrapper="group_name"]').hide()

    if (typeof agency_user_name === 'undefined' || !agency_user_name.length) {
      const $link_to_sub = $('.main-header').find('[data-show-sub-menu]')

      if ($link_to_sub.length) {
        $link_to_sub.removeAttr('data-show-sub-menu')
        $link_to_sub.attr('href', $link_to_sub.next('ul').find('a').first().attr('href'))
      }
    }

    if ($('.alert').length) {
      $form.addClass('form-wrapper--error')
      $form_new_agency.addClass('form-wrapper--error')
    } else if ($('.form-wrapper--error')) {
      $form.removeClass('form-wrapper--error')
      $form_new_agency.removeClass('form-wrapper--error')
    }
    $(document).on('click', '.alert-danger .close', function (e) {
      e.preventDefault()
      $form.removeClass('form-wrapper--error')
      $form_new_agency.removeClass('form-wrapper--error')
    })

    $('.agency-filter-results')
      .find('input[type=text]')
      .each(function (index, element) {
        const $this = $(this)
        if ($this.val().length) $this.addClass('with-value')
      })

    $el
      .find('.dates')
      .each(function () {
        $(this).dateRangePicker({
          format: date_format,
          startOfWeek: start_of_week,
          language,
          separator: ' ',
          autoClose: true,
          startDate: start_date,
          endDate: end_date,
          selectForward: true,
          hoveringTooltip: false,
          stickyMonths: true,
          minDays: 2,
          inline_mobile_panel: is_inline,
          inline: is_inline,
          // singleDate: true,
          showTopbar: false,
          getValue() {
            if ($check_in.val() && $check_out.val()) {
              return `${$check_in.val()} ${$check_out.val()}`
            }
            return ''
          },
          setValue(s, s1, s2) {
            $check_in.val(s1)
            $check_out.val(s2)
          },
          beforeShowDay(t) {
            const style = t.getDay() === 0 ? 'weekend' : ''
            return [true, style, '', true]
          },
        })
        if (is_inline) {
          $check_in = $check_in.add($('.inline-wrapper').find('.start-day'))
          $check_out = $check_out.add($('.inline-wrapper').find('.end-day'))
        }
      })
      .on('datepicker-open', function () {
        const is_date = $el.find('.dates').data('is-date')
        $check_in = $el.find('.check-in:visible')
        $check_out = $el.find('.check-out:visible')
        const datepicker_width = $('.date-picker-wrapper').width()
        const window_width = $(window).width()
        const diff = window_width - $check_in.offset().left
        $('.date-picker-wrapper').css({
          left: $check_in.closest('.input.date').offset().left - 5,
        })
      })
      .on('datepicker-first-date-selected', function (event, obj) {
        // Set checkin input value
        $el.find('.date.check-out').removeAttr('disabled')
        $check_in.removeClass('active').val(dayjs(obj.date1).format(date_format))
        $check_out.addClass('active').val('')
        const datepicker_width = $('.date-picker-wrapper').outerWidth()
        const offset_left = $('.date-picker-wrapper').offset().left
        const offset_right = datepicker_width + offset_left
        const window_width = $(window).width()
        const check_in_width = $check_in.outerWidth()
        const difference = window_width - offset_right

        if (check_in_width < difference) {
          $('.date-picker-wrapper').css('left', $(this).find('.input.date').last().offset().left + 15)
        }

        const selected_day = dayjs(obj.date1).format('DDMMYYYY')
        $el.find('.dates').data('first-day', selected_day)
        $el.find('.check-in').addClass('with-value')
        $el.find('.check-out').removeClass('with-value')
      })
      .on('datepicker-closed', function (event) {
        event.preventDefault()
        if ($el.find('.check-out').val()) $el.find('.check-out').addClass('with-value')
        validateDatesAndBookingCode()
      })

    $el.find('.date, .date input').on('click', function (e) {
      e.preventDefault()
      let first_day
      let last_day
      const $date = $(this)
      const is_checkin = !!($date.find('.check-in').length || $date.hasClass('.check-in'))
      const is_checkout = !!($date.find('.check-out').length || $date.hasClass('.check-out'))
      const in_val = $check_in.val()
      const out_val = $check_out.val()
      $el.find('.date').removeClass('active').find('input').removeClass('active')
      $date.addClass('active').find('input').addClass('active')

      if (is_checkin) {
        $el.find('.dates').data('is-date', 'check_in')
      } else if (is_checkout) {
        $el.find('.dates').data('is-date', 'check_out')
      }

      if (is_checkin && !out_val && in_val) {
        $el.find('.date').find('input').removeClass('active')
        $date.find('input').addClass('active')
        $date.closest('.dates').data('dateRangePicker').updateFirstDate()
        $('.date-picker-wrapper').css({
          left: $date.closest('.dates').find('.check-in').offset().left - 5,
        })
      } else if (is_checkout && !in_val) {
        $el.find('.date').removeClass('active').find('input').removeClass('active')
        return false
      } else if (is_checkout && in_val) {
        first_day = $el.find('.dates').data('first-day')
        last_day = $date.find('.check-out').val()
        $date.closest('.dates').data('dateRangePicker').updateLastDate(first_day)
        setTimeout(function () {
          $el.find('.check-out').val(last_day)
        }, 0)
        $('.date-picker-wrapper').css({
          left: $date.closest('.dates').find('.check-out').offset().left - 5,
        })
      } else if (is_checkin && out_val) {
        last_day = $('.day.last-date-selected').attr('time')
        $date.closest('.dates').data('dateRangePicker').setLastDate(last_day)
        $('.day').removeClass('hovering-permanent')
        $('.date-picker-wrapper').css({
          left: $date.closest('.dates').find('.check-in').offset().left - 5,
        })
      }
    })

    $el.find('.remove-dates').on('click', function (event) {
      event.preventDefault()
      event.stopPropagation()
      removeDates($el)
    })

    // BOOKINGS FILTERS
    // =========================================================================
    const $filterDate = $el.find('.js-filter-start-date')

    if ($filterDate.length) {
      const filtersContainer = $el.get(0) // transform to js object
      const filterDateContainer = filtersContainer.querySelector('.js-agency-filter-date')
      const startDate = filtersContainer.querySelector('.js-filter-start-date')
      const endDate = filtersContainer.querySelector('.js-filter-end-date')
      const removeDatesBtn = filtersContainer.querySelector('.js-filter-remove-dates')

      $filterDate.dateRangePicker({
        autoClose: true,
        format: date_format,
        startOfWeek: start_of_week,
        language,
        showTopbar: false,
        selectForward: true,
        hoveringTooltip: false,
        stickyMonths: true,
        singleMonth: true,
        setValue(s, s1, s2) {
          startDate.value = s1 || ''
          endDate.value = s2 || ''
        },
        getValue() {
          if (startDate.value && endDate.value) return `${startDate.value} to ${endDate.value}`
          return ''
        },
      })

      $filterDate.on('datepicker-change', function (event, obj) {
        filterDateContainer.classList.add('has-value')
      })

      removeDatesBtn.addEventListener('click', () => {
        filterDateContainer.classList.remove('has-value')
        $filterDate.data('dateRangePicker').clear()
      })
    }

    $('.booking-code input, .client input').on('keyup', function () {
      const $this = $(this)
      const val = $this.val()
      if (val) {
        $this.addClass('with-value')
      } else {
        $this.removeClass('with-value')
      }
      validateDatesAndBookingCode()
    })

    $('.apply-bookings-filters').on('click', function (e) {
      e.preventDefault()

      const serializedFilterValues = getFilterValuesSerialized()

      window.location.href = `${location.protocol}//${location.host}${location.pathname}?${serializedFilterValues}`
    })

    function removeDates($element) {
      if ($element.find('.dates').data('dateRangePicker')) {
        $element.find('.dates').data('dateRangePicker').clear()
      }
      $element.find('.check-in').removeClass('with-value')
      $element.find('.check-out').removeClass('with-value')
    }

    $('.booking-code input').on('keyup', function () {
      validateDatesAndBookingCode()
    })

    function validateDatesAndBookingCode() {
      if ($el.find('.booking-code input').val() || ($check_in.val() && $check_out.val())) {
        $el.find('.item.dates').removeClass('error')
        $el.find('.error-form').hide()
        $el.find('.reset-wrap').removeClass('hide')
        return true
      }
      $el.find('.item.dates').addClass('error')
      $el.find('.error-form').show()
      return false
    }

    document.querySelector('.js-trigger-filters')?.addEventListener('click', function () {
      if (!filterForm) return

      if (currentDevice.isMobile) {
        $.fancybox.open($(filterForm))
      } else {
        filterForm.classList.toggle('is-collapsed')
      }
    })

    // Reset button should be visible on load if any filter is applied
    function setRemoveFiltersButtonVisibility() {
      if (!filterForm) return

      const resetWrap = filterForm.querySelector('.js-reset-wrap')
      const isAnyCheckboxChecked = filterForm.querySelectorAll('.js-multi-select-option:checked')

      if (isAnyCheckboxChecked.length) {
        resetWrap.classList.remove('is-hidden')
        return
      }

      const allFilterInputs = filterForm.querySelectorAll('.js-filter-hotel, input[type=text]')
      const hasAnyInputValue = [...allFilterInputs].some(input => input.value !== '')
      resetWrap.classList.toggle('is-hidden', !hasAnyInputValue)
    }

    setRemoveFiltersButtonVisibility()

    $(document).on('click', '.agency-filter-block .reset', function (e) {
      e.preventDefault()

      if (!filterForm) return

      filterForm.querySelector('.js-filter-hotel').value = ''

      filterForm.querySelectorAll('input[type=text]').forEach(function (elem) {
        elem.value = ''
        elem.classList.remove('with-value')
      })

      filterForm.querySelectorAll('.js-multi-select-option:checked').forEach(function (elem) {
        elem.checked = false
      })

      filterForm.querySelectorAll('.js-multi-select-apply').forEach(function (elem) {
        elem.click()
      })

      filterForm.querySelector('.js-filter-remove-dates').click()

      filterForm.querySelector('.js-reset-wrap').classList.add('is-hidden')
    })

    $(document).on('click', '#same_comercial_data_yes', function () {
      $('#comercial-data').addClass('hidden')
    })
    $(document).on('click', '#same_comercial_data_no', function () {
      $('#comercial-data').removeClass('hidden')
    })

    // GET BOOKINGS
    // =========================================================================
    let htmlBookings = ''
    const $bookingsContainer = document.querySelector('.js-bookings-list')

    const spinner1 = $('.js-seg')
    const spinner2 = $('.js-min')
    let bookingsSpinnerTimeout
    const $jobSpinner = document.querySelector('.js-job-spinner')

    let page = parseInt($('#bookings_job_page').val())
    let prefix = document.querySelector('html').getAttribute('prefix')
    prefix = prefix !== '/' && prefix !== '/es' ? prefix : ''

    let url = ''
    let currentSpinnerObserver
    let firstResponseWithContent = false

    const handleNextPageRequest = () => {
      if (isInViewport($jobSpinner)) {
        // If spinner is in viewport requests next page
        page = page + 1
        ajax_job_call(getBookings, page)

        // Reset spinner messages
        htmlBookings.length && resetSpinner()
      } else {
        // If spinner is not in viewport creates observer to request next page when intersects
        currentSpinnerObserver = inViewport(
          $jobSpinner,
          element => {
            if (element.isIntersecting) {
              // Shut down current observer
              currentSpinnerObserver.unobserve($jobSpinner)
              // Request next page
              page = page + 1
              ajax_job_call(getBookings, page)
              // Reset spinner messages
              htmlBookings.length && resetSpinner()
            }
          },
          { threshold: 0.5 }
        )
      }
    }

    const initializeBookingItems = () => {
      $('.booking-options .formikation').find('input[type=checkbox], input[type=radio], select').not('.chosen-select').formikation()
      $('.print-trigger').on('click', function () {
        $(this).toggleClass('active').find('.print-dropdown').toggleClass('active')
      })
      $('.print-trigger').on('mouseleave', function () {
        $(this).removeClass('active').find('.print-dropdown').removeClass('active')
      })
    }

    const getBookings = (jid, page) => {
      url = `${prefix}/agency_bookings_jobs/${jid}/?page=${page}`

      watchJob({
        url,
        timeout: 2000,
        onResponse: response => {
          // When responds with a partial or a complete response
          const parsedResponse = JSON.parse(response)
          if (parsedResponse.total_bookings != null) {
            $('.js-total-bookings').text(`(${parsedResponse.total_bookings}):`)
          }
        },
        onPartialResponse: response => {
          // When responds with a partial response
          const parsedResponse = JSON.parse(response)

          // Append html
          if (parsedResponse.html_replacement && parsedResponse.html_replacement != '""') {
            $bookingsContainer.innerHTML = htmlBookings + JSON.parse(parsedResponse.html_replacement)
            initializeBookingItems()
            resetSpinner()

            !firstResponseWithContent && (firstResponseWithContent = true)

            if (firstResponseWithContent) {
              $('.bookings-header').show()
              $('.export-wrap').show()
            }

            document.dispatchEvent(new CustomEvent('loadedReserve', { detail: { message: 'Added booking to html.' } }))
          }
        },
        onComplete: response => {
          // When responds with a complete response
          const parsedResponse = JSON.parse(response)

          const hasValue = value => value || value === 0 || value === '0'

          const currentPage = hasValue(parsedResponse.page) ? parseInt(parsedResponse.page, 10) : -1
          const totalPages = hasValue(parsedResponse.total_booking_pages) ? parseInt(parsedResponse.total_booking_pages, 10) : -1

          if (!htmlBookings.length && parsedResponse.read_timeout) {
            $('.agency-no-bookings-title').toggleClass('hidden')
            $('.bookings-timeout-title').toggleClass('hidden')
          }

          if (parsedResponse.html_replacement && parsedResponse.html_replacement != '""') {
            htmlBookings += JSON.parse(parsedResponse.html_replacement)
            $bookingsContainer.innerHTML = htmlBookings
            initializeBookingItems()
          }

          if (currentPage !== -1 && totalPages !== -1) {
            // All requests finished
            if (parseInt(parsedResponse.page) >= parseInt(parsedResponse.total_booking_pages)) {
              $jobSpinner.classList.add('is-hidden')
              spinner1.addClass('hidden')
              spinner2.addClass('hidden')

              // If there are no bookings, show "no reservations" container
              if (!htmlBookings.length) {
                $('.agency-no-bookings').toggleClass('hidden')
                $('.export-wrap').hide()
              }
            } else {
              handleNextPageRequest()
            }
          }

          document.dispatchEvent(new CustomEvent('loadedReserve', { detail: { message: 'Completed add booking to html.' } }))
        },
      })
    }

    if ($bookingsContainer && $('#bookings_job_id').length > 0) {
      getBookings($('#bookings_job_id').val(), page)
      setTimeout(function () {
        spinner1.addClass('hidden')
        spinner2.removeClass('hidden')
      }, 10000)
      $('.bookings-header').hide()
    }

    function resetSpinner() {
      bookingsSpinnerTimeout && clearTimeout(bookingsSpinnerTimeout)
      spinner1.removeClass('hidden')
      spinner2.addClass('hidden')
      bookingsSpinnerTimeout = setTimeout(function () {
        spinner1.addClass('hidden')
        spinner2.removeClass('hidden')
      }, 10000)
    }
    $('.export-csv-link').on('click', function (e) {
      if ($('.export-csv-link').attr('href') == '#') {
        e.preventDefault()
        toogleExportLink(false)
        ajax_job_call(watchXlsJob, 1, -1)
      }
    })

    function toogleExportLink(show = true) {
      if (show) {
        $('.export-csv-link-wrapper').removeClass('hidden')
        $('.spinner').addClass('hidden')
      } else {
        $('.export-csv-link-wrapper').addClass('hidden')
        $('.spinner').removeClass('hidden')
      }
    }

    function watchXlsJob(jid) {
      url = `${prefix}/agency_bookings_jobs/${jid}/`
      $.getJSON(url, function (job) {
        toogleExportLink(false)
        // While the job is not finished
        if (job.at != job.total) {
          setTimeout(function () {
            watchXlsJob(jid)
          }, 5000)
        } else {
          const $exportLink = $('.export-csv-link')
          toogleExportLink(true)
          $('.bookings-header').show()
          window.open(job.bookings_xls, '_blank')
          $exportLink.attr({ href: job.bookings_xls, target: '_blank' })
          $exportLink.closest('.link').addClass('is-download-ready')
        }
      }).done(function () {})
    }

    function ajax_job_call(callback, page, per_page) {
      const agencyFilterBlock = document.querySelector('.agency-filter-block')

      if (!agencyFilterBlock) return

      const params = getFilterValues(page, per_page)

      const url = `${prefix}/agency_bookings_jobs`

      $.ajax({
        type: 'POST',
        url,
        data: params,
        dataType: 'json',
        success(data) {
          const jid = data.job_id
          callback(jid, page)
        },
        error(result) {
          console.log('Error calling agency_bookings_jobs')
        },
      })
    }

    $('#permanent_login').on('change', function () {
      if (this.checked) {
        dataLayer.push(JSON.parse($(this).attr('data-gtm-custom')))
      }
    })

    // AGENTS' REGISTRY FROM
    // =========================================================================

    function chain_agency_form_fields(country_selector, community_selector, province_selector, settlement_selector, code_selector) {
      const $country = $(country_selector)
      const $community = $(community_selector)
      const $province = $(province_selector)
      const $settlement = $(settlement_selector)
      const $postal_code = $(code_selector)

      const text_to_substitute = 'Otros'

      if ($country.length > 0 && $community.length > 0) {
        $country.on('change', function () {
          if ($postal_code) {
            $postal_code.trigger('change')
          }
          $community.val($community.find('option:first').val())
          $province.val($province.find('option:first').val())
          $settlement.val($settlement.find('option:first').val())
          $community.addClass('disabled').attr('disabled', 'disabled').closest('.input-text').addClass('loading')
          $community.trigger('formikation.update')
          if ($country.val() != '') {
            $.getJSON(`/ajax_content/communities_by_country_id?country_id=${$country.val()}`, function (response) {
              if (response != null) {
                const first_option = $community.find('option:first-child')
                $community.empty().append(first_option)
                for (let i = 0; i < response.length; i++) {
                  let option_text = response[i][0]
                  if (option_text == text_to_substitute) option_text = $country.children('option:selected').text()
                  $community.append(`<option value="${response[i][1]}">${option_text}</option>`)
                }
              }
              $community.removeClass('disabled').attr('disabled', false).closest('.input-text').removeClass('loading')
            })
          }
        })
      }

      if ($community.length > 0 && $province.length > 0) {
        $community.on('change', function () {
          $province.val($province.find('option:first').val())
          $settlement.val($settlement.find('option:first').val())
          $province.addClass('disabled').attr('disabled', 'disabled').closest('.input-text').addClass('loading')
          $province.trigger('formikation.update')
          if ($community.val() != '') {
            $.getJSON(`/ajax_content/provinces_by_community_id?community_id=${$community.val()}`, function (response) {
              if (response != null) {
                const first_option = $province.find('option:first-child')
                $province.empty().append(first_option)
                for (let i = 0; i < response.length; i++) {
                  let option_text = response[i][0]
                  if (option_text == text_to_substitute) option_text = $country.children('option:selected').text()
                  $province.append(`<option value="${response[i][1]}">${option_text}</option>`)
                }
              }
              $province.removeClass('disabled').attr('disabled', false).closest('.input-text').removeClass('loading')
            })
          }
        })
      }

      if ($province.length > 0 && $settlement.length > 0) {
        $province.on('change', function () {
          $settlement.val($settlement.find('option:first').val())
          $settlement.addClass('disabled').attr('disabled', 'disabled').closest('.input-text').addClass('loading')
          $settlement.trigger('formikation.update')
          if ($province.val() != '') {
            $.getJSON(`/ajax_content/settlements_by_province_id?province_id=${$province.val()}`, function (response) {
              if (response != null) {
                const first_option = $settlement.find('option:first-child')
                $settlement.empty().append(first_option)
                for (let i = 0; i < response.length; i++) {
                  let option_text = response[i][0]
                  if (option_text == text_to_substitute) option_text = $country.children('option:selected').text()
                  $settlement.append(`<option value="${response[i][1]}">${option_text}</option>`)
                }
              }
              $settlement.removeClass('disabled').attr('disabled', false).closest('.input-text').removeClass('loading')
            })
          }
        })
      }

      $settlement.on('change', function () {
        if ($postal_code) {
          $postal_code.trigger('change')
        }
      })

      if ($country.length > 0 && $postal_code.length > 0) {
        $postal_code.on('change', function () {
          $postal_code.removeClass('parsley-error')
        })
      }
    }

    function addData() {
      // copies second step's selectors values into third step's
      const selectors = ['country', 'province', 'community', 'settlement']
      let i = selectors.length
      let $current
      let curr_val
      let curr_text
      let $opt
      let $commercial_select

      while (i--) {
        $current = $(`[name="${selectors[i]}"]`)
        curr_val = $current.val()
        ;(curr_text = $current.find('option:selected').text()), ($commercial_select = $(`[name="comercial_${selectors[i]}"]`))

        if ($commercial_select.find('option').length === 1) {
          $commercial_select.append(`<option value="${curr_val}" selected>${curr_text}</option>`)
        } else {
          $opt = $current.find(`option:contains("${curr_text}")`)
          $commercial_select.val($opt.val())
        }
      }

      // copies second step's inputs values into third step's
      $('[name="comercial_name"]').val($('[name="name"]').val())
      $('[name="comercial_address"]').val($('[name="address"]').val())
      $('[name="comercial_postal_code"]').val($('[name="postal_code"]').val())
      $('[name="comercial_phone_number"]').val($('[name="phone_number"]').val())
      $('[name="comercial_email"]').val($('[name="email"]').val())

      chain_agency_form_fields(
        '[name="comercial_country"]',
        '[name="comercial_community"]',
        '[name="comercial_province"]',
        '[name="comercial_settlement"]',
        '[name="comercial_postal_code"]'
      )
    }

    function agency_form_cif_validation(cif_selector, name_selector, country_selector) {
      const $country = $(country_selector)
      const $name = $(name_selector)
      const $cif = $(cif_selector)

      if ($country.length > 0 && $name.length > 0 && $cif.length > 0) {
        $(`${country_selector}, ${name_selector}, ${cif_selector}`).on('change', function () {
          if ($country.val() == '56' && $name.val() != '' && $cif.val() != '') {
            $.getJSON(`/ajax_content/cif_code_info?code=${$cif.val()}&name=${$name.val()}`, function (response) {
              if (response === null) {
                $cif.removeClass('parsley-error')
                $name.removeClass('parsley-error')
              } else {
                // If all parameters of AJAX call are OK
                if (response['agency_user'].StatusValue == 'OK') {
                  // If one contributor is returned in response at least
                  if (response['agency_user'].Contributors !== 'undefined') {
                    const contributor = response['agency_user'].Contributors[0]
                    // If we identify contributor and it is valid in our application
                    // Note: Valid constributors are those ones who matches CIF code (name dont have to match exactly)
                    if (
                      contributor.resultadoField == 'IDENTIFICADO' ||
                      contributor.resultadoField == 'NO IDENTIFICADO-SIMILAR' ||
                      contributor.resultadoField == 'IDENTIFICADO-REVOCADO'
                    ) {
                      $name.removeClass('parsley-error')
                      $cif.removeClass('parsley-error')
                      if (/^[ABCDEFGHKLMNPQSX]/.test($cif.val().substr(0, 1)) && $name.val().toUpperCase() != contributor.nombreField) {
                        $name.val(contributor.nombreField)
                      }
                    } else {
                      // Set both fields as invalid because its not know which field is invalid
                      $name.addClass('parsley-error')
                      $cif.addClass('parsley-error')
                    }
                  }
                  // If no contributor sent in response
                  else {
                    // Set both fields as invalid because its not know which field is invalid
                    $name.addClass('parsley-error')
                    $cif.addClass('parsley-error')
                  }
                }
                // If parameters of AJAX call are NOK
                else if (response['agency_user'].StatusValue == 'NOK') {
                  // Set both fields as invalid because its not know which field is invalid
                  $name.addClass('parsley-error')
                  $cif.addClass('parsley-error')
                }
              }
            })
          } else {
            $cif.removeClass('parsley-error')
            $name.removeClass('parsley-error')
          }
        })
      }
    }

    function deleteData() {
      $('[name="comercial_country"], [name="comercial_province"],[name="comercial_community"], [name="comercial_community"], [name="comercial_settlement"] ').val('')
      $('.comercial-data')
        .find('[name="comercial_country"], [name="comercial_province"],[name="comercial_community"], [name="comercial_community"], [name="comercial_settlement"]')
        .val('')
      $(
        '[name="comercial_name"], [name="comercial_address"], [name="comercial_postal_code"], [name="comercial_postal_code"], [name="comercial_fax"], [name="comercial_phone_number"], [name="comercial_email"]'
      ).val('')
    }

    $('#commercialCheck').on('change', function () {
      if (this.checked) {
        addData()
      } else {
        deleteData()
      }
    })

    $('#business_type').on('change', function () {
      if ($(this).val() == 1) {
        // Agencia
        $('[data-wrapper="management_type"]').show()
        $('#management_type').attr('data-parsley-required', 'true')
        $formz.parsley().refresh()
      } else {
        // Empresa o touroperador
        $('[data-wrapper="management_type"], [data-wrapper="group_name"]').hide()
        $('#management_type').removeAttr('data-parsley-required')
        $formz.parsley().refresh()
      }
      FORMZ.required_fields.init($formz.find('[data-parsley-required]:not([data-parsley-errors-container])'))
    })

    $('#management_type').on('change', function () {
      if ($(this).val() == 1) {
        $('[data-wrapper="group_name"]').show()
      } else {
        $('[data-wrapper="group_name"]').hide()
      }
    })
    $form_new_agency.on('submit', function () {
      $.fancybox.open($('#loading-register'))
    })
    $form_change_password_user.on('submit', function () {
      $.fancybox.open($('#ok-password-change'))
    })
    $form_users.on('submit', function () {
      $.fancybox.open($('#in-changes'))
    })
    if ($agents_form.length > 0) {
      chain_agency_form_fields('#country.chained', '#community.chained', '#province.chained', '#settlement.chained', '#postal_code.validate-on-leave')
      chain_agency_form_fields(
        '#comercial_country.chained',
        '#comercial_community.chained',
        '#comercial_province.chained',
        '#comercial_settlement.chained',
        '#comercial_postal_code.validate-on-leave'
      )
      agency_form_cif_validation('#cif', '#name', '#country.chained')
      chain_agency_form_fields(
        '#iberostar_api_agency_user_comercial_country',
        '#iberostar_api_agency_user_agencomcomm',
        '#iberostar_api_agency_user_agencomprov',
        '#iberostar_api_agency_user_agencompob',
        '#iberostar_api_agency_user_agencomcp'
      )
      chain_agency_form_fields(
        '#not_needed',
        '#iberostar_api_agency_user_agencomm',
        '#iberostar_api_agency_user_agenprov',
        '#iberostar_api_agency_user_agenpob',
        '#iberostar_api_agency_user_agencp'
      )
      // chain_agency_form_fields("#iberostar_api_agency_user_paiscodi", "#iberostar_api_agency_user_agenprov", "#iberostar_api_agency_user_agencomprov", "#iberostar_api_agency_user_agenpob", "#iberostar_api_agency_user_agencp");

      if ($('#postal_code').length > 0) {
        Parsley.addValidator('zip', {
          validateString(value, _ignoreValue, instance) {
            return !$(instance.element).hasClass('parsley-error')
          },
        })
      }
      if ($('#group_name').length > 0) {
        $('#management_type').on('change', function () {
          if ($(this).val() === '1') {
            $('#group_name').attr('data-parsley-required', 'true').closest('.fz-field').find('.t-fb-label').addClass('required-mark')
            FORMZ.required_fields.init($('#group_name'))
            /* Errors will be displayed on nearest fz-error-conytainer */
            const parsley_config = {
              errorsContainer(pe) {
                const $err = pe.$element.closest('.fz-col').find('.fz-error-container')
                return $err
              },
            }
          } else {
            $('#group_name').removeAttr('data-parsley-required').closest('.fz-field').find('.t-fb-label').removeClass('required-mark')
          }
        })
      }
    }
  }

  $(document).on('keyup', '.password-row input.password, .password-row input#password', function () {
    const $el = $(this)
    if ($el.hasClass('parsley-success')) {
      $el.closest('.password-row').find('.validation-errors').addClass('hidden')
      $el.closest('.password-row').find('.validation-success').removeClass('hidden')
    } else {
      $el.closest('.password-row').find('.validation-errors').removeClass('hidden')
      $el.closest('.password-row').find('.validation-success').addClass('hidden')
    }
  })

  /**
   * This on click event show and hide the steps into register form when title is clicked
   */
  $(document).on('click', '.session-page .block-form .introduction-module', function () {
    const $el = $(this)
    const $content = $el.closest('.form-wrapper').find('.section-wrap')
    if ($content.hasClass('close')) {
      $content.removeClass('close').slideDown()
      $content.find('.row').addClass('active')
      $el.removeClass('close')
    } else {
      $content.addClass('close').slideUp()
      $content.find('.row').removeClass('active')
      $el.addClass('close')
    }
  })

  /**
   * This on click event removes flash_message into register form when close icon is clicked
   */
  $(document).on('click', '.wrapper-flash a.close.s.s-close-fat', function (e) {
    $('.alert.alert-danger').remove()
    $('.alert.alert-notice').remove()
    $('.alert.alert-success').remove()
  })

  IB.iberostar_pro = {
    init,
  }
})(jQuery)

$(function () {
  /** Iberostar Pro home doesnt need to init this JS until register form is loaded */
  if ($('.iberostarpro-home .container-parallax').length) return
  IB.iberostar_pro.init()
})
