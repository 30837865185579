import { hasParam } from '../../vendors/urlParser/urlParser'
import Modal from '../modal/modal'

if (hasParam('form_pg_sent')) {
  Modal.show('price-guaranteed-form-sent-success', {
    onShow: () => {
      ScrollLock.on()
    },
    onClose: () => {
      ScrollLock.off()
    },
    awaitCloseAnimation: true,
  })
}
